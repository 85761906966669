import { FULFILLMENT_METHODS } from '../constants';
import {
  getExcludedStateName,
  getLocalStore,
  isActiveOrInSeason,
  isBopisOutOfStock,
  isBOPISUnavailable,
  isBOSSBackordered,
  isBOSSDominant,
  isBOSSOutOfStock,
  isLocationInExcludedStates,
  isNoBOPISForAppliance,
  isStoreOnlyWithClearance
} from '../helper/utils';
import { getTemplate } from './templateHelper';

export const handleFallbackMode = ({
  bossFulfillment,
  configuratorHideQuantity,
  fallbackMode,
  isCartTiles,
  isSpecialOrder
}) => {
  if (!bossFulfillment || (!fallbackMode && !bossFulfillment?.fallbackMode)) {
    return null;
  }
  return {
    ...getTemplate({
      ffm: 'PICKUP',
      subTemplateFields: {
        fulfillment: bossFulfillment,
        isCartTiles
      },
      templateFields: {
        fulfillment: bossFulfillment,
        fallbackMode,
        isSpecialOrder,
        configuratorHideQuantity
      },
      templateName: 'DefaultBoSS'
    }),
    enabled: true,
    method: FULFILLMENT_METHODS.BOSS,
    quantityLimitExceed: false
  };
};

const getOffshoreBoss = ({
  addOnsAssemblyAndMore,
  shippingFulfillment,
  itemId,
  fulfillment,
  method
}) => {
  return {
    ...getTemplate({
      ffm: 'PICKUP',
      templateFields: {
        addOnsAssemblyAndMore,
        isShipToHomeEligible: !!shippingFulfillment,
        itemId,
        stateName: getExcludedStateName(fulfillment?.bossExcludedShipState)
      },
      templateName: 'OffshoreBossUnavailable'
    }),
    enabled: false,
    excluded: true,
    method
  };
};

export const handleNoFfmOrBossExcluded = ({ fulfillment, itemId, method, shippingFulfillment }) => {
  if (
    (fulfillment?.fulfillmentOptions && fulfillment?.fulfillmentOptions.length !== 0)
    || !fulfillment?.bossExcludedShipState
  ) {
    return null;
  }

  return getOffshoreBoss({
    shippingFulfillment,
    itemId,
    fulfillment,
    method
  });
};

export const handleNoPickupAndBossExcluded = ({
  addOnsAssemblyAndMore,
  bopisFulfillment,
  bossFulfillment,
  fulfillment,
  itemId,
  method,
  shippingFulfillment
}) => {
  if (
    [null, undefined].includes(fulfillment?.bossExcludedShipState)
    || bossFulfillment
    || bopisFulfillment
  ) {
    return null;
  }
  return getOffshoreBoss({
    addOnsAssemblyAndMore,
    shippingFulfillment,
    itemId,
    fulfillment,
    method
  });
};

export const handleNoBopisForAppliance = ({ fulfillment, itemId, productType }) => {
  if (!isNoBOPISForAppliance(productType, fulfillment)) {
    return null;
  }
  return {
    ...getTemplate({
      ffm: 'SHARED',
      templateFields: {
        itemId,
        type: FULFILLMENT_METHODS.BOPIS
      },
      templateName: 'NotAvailable'
    }),
    unavailable: true,
    method: FULFILLMENT_METHODS.BOPIS,
    enabled: false
  };
};

const getInStockNearBy = ({
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  covidEnabled,
  fulfillment,
  isCartTiles,
  method,
  nearByStoreLocation,
  pickupStoreHours,
  pricing,
  quantity,
  quantityLimitExceed
}) => {
  return {
    ...getTemplate({
      ffm: 'PICKUP',
      subTemplateFields: {
        bopisMinimumThreshold,
        bopisMinimumThresholdStores,
        fulfillment,
        pricing,
        quantity
      },
      templateFields: {
        covidEnabled,
        isCartTiles,
        nearByStoreLocation,
        pickupStoreHours
      },
      subTemplateName: 'InStockNearByStoreDetails',
      templateName: 'InStockNearByStore'
    }),
    enabled: true,
    isPickupNearby: true,
    method,
    quantityLimitExceed
  };
};

export const handleFfmUnavailableOrClearance = ({
  bopisFulfillment,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  bossFulfillment,
  disablePickupNearBy,
  fulfillment,
  isCartTiles,
  isClearanceInStockOrLQTY,
  itemId,
  method,
  nearByQuantityLimitExceed,
  nearByStoreLocation,
  pickupService,
  pickupStoreHours,
  pricing,
  quantity,
  type
}) => {
  if (
    fulfillment?.fulfillmentOptions
    && pickupService
    && (!isStoreOnlyWithClearance(fulfillment, type) || isClearanceInStockOrLQTY)
  ) {
    return null;
  }

  if (!disablePickupNearBy && nearByStoreLocation && !isCartTiles) {
    if (nearByStoreLocation.inventory?.isInStock) {
      return getInStockNearBy({
        bopisMinimumThreshold,
        bopisMinimumThresholdStores,
        fulfillment: bopisFulfillment,
        isCartTiles,
        method,
        nearByStoreLocation,
        pickupStoreHours,
        pricing,
        quantity,
        quantityLimitExceed: nearByQuantityLimitExceed
      });
    }
    if (nearByStoreLocation.inventory?.isLimitedQuantity && !bossFulfillment) {
      return {
        ...getTemplate({
          ffm: 'PICKUP',
          templateFields: {
            itemId,
            location: nearByStoreLocation
          },
          templateName: 'LimitedStockByStoreNearby'
        }),
        method,
        enabled: false
      };
    }
  }
  return {
    ...getTemplate({
      ffm: 'SHARED',
      templateFields: {
        itemId,
        type: FULFILLMENT_METHODS.BOPIS
      },
      templateName: 'NotAvailable'
    }),
    enabled: false,
    method,
    outOfStock: true,
    unavailable: true,
    uavl: true
  };
};

export const handleNearBy = ({
  bopisFulfillment,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  bossFulfillment,
  bossQuantityLimitExceed,
  configuratorHideQuantity,
  covidEnabled,
  disablePickupNearBy,
  isCartTiles,
  isClearanceInStockOrLQTY,
  isSpecialOrder,
  itemId,
  method,
  nearByQuantityLimitExceed,
  nearByStoreLocation,
  pickupStoreHours,
  pricing,
  quantity
}) => {
  if (disablePickupNearBy || !nearByStoreLocation || (isClearanceInStockOrLQTY && !isCartTiles)) {
    return null;
  }

  if (nearByStoreLocation.inventory?.isInStock) {
    return getInStockNearBy({
      bopisMinimumThreshold,
      bopisMinimumThresholdStores,
      covidEnabled,
      fulfillment: bopisFulfillment,
      isCartTiles,
      method,
      nearByStoreLocation,
      pickupStoreHours,
      pricing,
      quantity,
      quantityLimitExceed: nearByQuantityLimitExceed
    });
  }
  if (bossFulfillment) {
    if (!nearByStoreLocation?.inventory?.isInStock && !isBOSSOutOfStock(bossFulfillment)) {
      return {
        ...getTemplate({
          ffm: 'PICKUP',
          subTemplateFields: {
            fulfillment: bossFulfillment,
            isCartTiles
          },
          templateFields: {
            fulfillment: bossFulfillment,
            isSpecialOrder,
            configuratorHideQuantity
          },
          templateName: 'DefaultBoSS'
        }),
        enabled: true,
        isPickupNearby: true,
        method: FULFILLMENT_METHODS.BOSS,
        quantityLimitExceed: bossQuantityLimitExceed
      };
    }
  }
  if (nearByStoreLocation.inventory?.isLimitedQuantity) {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        templateFields: {
          itemId,
          location: nearByStoreLocation
        },
        templateName: 'LimitedStockByStoreNearby'
      }),
      enabled: false,
      method
    };
  }
  return null;
};

export const handleBopis = ({
  addOnsAssemblyAndMore,
  bopisFulfillment,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  bossFulfillment,
  bossQuantityLimitExceed,
  channel,
  configuratorHideQuantity,
  covidEnabled,
  fulfillment,
  isCartTiles,
  isClearanceInStockOrLQTY,
  isSpecialOrder,
  itemId,
  localStoreLocation,
  localStoreQuantityLimitExceed,
  method,
  nearByStoreLocation,
  pickupService,
  pickupStoreHours,
  pricing,
  quantity,
  shippingFulfillment,
  type
}) => {
  if (!bopisFulfillment) {
    return null;
  }

  if (isClearanceInStockOrLQTY) {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        templateFields: {
          clearanceStoreName: localStoreLocation?.storeName,
          isLimitedQuantity: localStoreLocation?.inventory?.isLimitedQuantity,
          pickupStoreHours,
          quantity: localStoreLocation?.inventory?.quantity
        },
        templateName: 'ClearanceStore'
      }),
      enabled: false,
      method
    };
  }

  if (!pickupService.fulfillable) {
    let templateFields = {
      isCartTiles,
      pickupStoreHours
    };
    if (localStoreLocation?.inventory?.quantity < 1) {
      if (isActiveOrInSeason(fulfillment)) {
        templateFields = { ...templateFields, location: localStoreLocation };
        return {
          ...getTemplate({
            ffm: 'PICKUP',
            templateFields,
            templateName: 'BopisNotFulfillableOOS'
          }),
          method,
          enabled: false
        };
      }
    } else if (localStoreLocation?.inventory?.isInStock) {
      templateFields = { ...templateFields, bopisFulfillment };
      return {
        ...getTemplate({
          ffm: 'PICKUP',
          templateFields,
          templateName: 'StoreOnly'
        }),
        method,
        enabled: false,
        tooltip: true
      };
    }
  }

  if (localStoreLocation?.inventory?.isLimitedQuantity) {
    if (bossFulfillment && !isBOSSOutOfStock(bossFulfillment)) {
      return {
        ...getTemplate({
          ffm: 'PICKUP',
          subTemplateFields: { fulfillment: bossFulfillment, isCartTiles },
          templateFields: {
            fulfillment: bossFulfillment,
            isSpecialOrder,
            configuratorHideQuantity
          },
          templateName: 'DefaultBoSS'
        }),
        method: FULFILLMENT_METHODS.BOSS,
        enabled: true,
        quantityLimitExceed: bossQuantityLimitExceed
      };
    }
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        templateFields: { itemId, location: localStoreLocation },
        templateName: 'LimitedStockByStore'
      }),
      method,
      enabled: false
    };
  }

  const notAvailableConditions = [
    isBopisOutOfStock(localStoreLocation, nearByStoreLocation),
    isBOPISUnavailable(localStoreLocation)
  ];
  if (notAvailableConditions.some((condition) => !!condition)) {
    return {
      ...getTemplate({
        ffm: 'SHARED',
        templateFields: { itemId, type: FULFILLMENT_METHODS.BOPIS },
        templateName: 'NotAvailable'
      }),
      method,
      enabled: false,
      unavailable: type === 'Store Only',
      outOfStock: true
    };
  }
  if (isBOSSDominant(bossFulfillment, localStoreLocation, quantity)) {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        subTemplateFields: { fulfillment: bossFulfillment, isCartTiles, quantity },
        templateFields: { fulfillment: bossFulfillment, isSpecialOrder, configuratorHideQuantity },
        templateName: 'DefaultBoSS'
      }),
      method: FULFILLMENT_METHODS.BOSS,
      enabled: true,
      quantityLimitExceed: bossQuantityLimitExceed
    };
  }
  return {
    ...getTemplate({
      ffm: 'PICKUP',
      subTemplateFields: {
        addOnsAssemblyAndMore,
        bopisMinimumThreshold,
        bopisMinimumThresholdStores,
        bossFulfillment,
        channel,
        fulfillment: bopisFulfillment,
        isCartTiles,
        isShipToHomeEligible: !!shippingFulfillment,
        itemId,
        pricing,
        quantity
      },
      templateFields: {
        configuratorHideQuantity,
        covidEnabled,
        fallbackMode: !!bopisFulfillment?.fallbackMode,
        isCartTiles,
        localStoreLocation,
        pickupStoreHours,
        deliveryTimeline: bopisFulfillment?.deliveryTimeline
      },
      subTemplateName: 'DefaultBOPISDetails',
      templateName: 'DefaultBOPIS'
    }),
    method,
    enabled: true,
    quantityLimitExceed: localStoreQuantityLimitExceed
  };
};

export const handleBoss = ({
  bossFulfillment,
  bossQuantityLimitExceed,
  configuratorHideQuantity,
  fulfillment,
  isCartTiles,
  isSpecialOrder,
  itemId,
  method,
  quantity,
  shippingFulfillment,
  type
}) => {
  if (!bossFulfillment) {
    return null;
  }

  const localStoreLocation = getLocalStore(bossFulfillment);
  if (isLocationInExcludedStates(localStoreLocation?.state, fulfillment.bossExcludedShipStates)) {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        templateFields: {
          itemId,
          isShipToHomeEligible: !!shippingFulfillment,
          stateName: getExcludedStateName(localStoreLocation?.state)
        },
        templateName: 'OffshoreBossUnavailable'
      }),
      method,
      enabled: false,
      excluded: true
    };
  }
  if (isBOSSBackordered(fulfillment)) {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        subTemplateFields: {
          backordered: fulfillment?.backordered,
          fulfillment: bossFulfillment,
          isCartTiles,
          quantity
        },
        templateFields: {
          backordered: fulfillment?.backordered,
          backorderedShipDate: fulfillment?.backorderedShipDate,
          configuratorHideQuantity,
          fulfillment: bossFulfillment,
          isSpecialOrder
        },
        templateName: 'DefaultBoSS'
      }),
      method,
      enabled: true,
      quantityLimitExceed: bossQuantityLimitExceed
    };
  }
  if (isBOSSOutOfStock(bossFulfillment) && type === 'Shared') {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        templateName: 'BOSSOutOfStock'
      }),
      method,
      enabled: false,
      unavailable: true
    };
  }
  if (!isBOSSOutOfStock(bossFulfillment)) {
    return {
      ...getTemplate({
        ffm: 'PICKUP',
        subTemplateFields: {
          fulfillment: bossFulfillment,
          isCartTiles,
          quantity
        },
        templateFields: {
          fulfillment: bossFulfillment,
          isSpecialOrder,
          configuratorHideQuantity
        },
        templateName: 'DefaultBoSS'
      }),
      method,
      enabled: true,
      quantityLimitExceed: bossQuantityLimitExceed
    };
  }
  return null;
};

export const handleDefaultPickup = ({ itemId, type }) => {
  const method = type === 'Store Only' ? FULFILLMENT_METHODS.STORE : FULFILLMENT_METHODS.BOPIS;
  return {
    ...getTemplate({
      ffm: 'SHARED',
      templateFields: {
        itemId,
        type: method
      },
      templateName: 'NotAvailable'
    }),
    method,
    enabled: false,
    unavailable: true
  };
};
