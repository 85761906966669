import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { DetaCountDownTimer } from './DetaCountDownTimer';
import { BackorderedAlert } from '../../alerts/BackorderedAlert';
import {
  formatDate,
  getDeliveryOptionsText,
  isMycartPage,
  showTodayOrTomorrow
} from '../../../helper/utils';
import { DeliveryDetails } from './DeliveryDetails';

const DefaultShippingDetails = ({
  itemId,
  deliveryStartDate,
  deliveryTimeline,
  locations,
  dynamicEtaMinutes,
  dynamicEtaHours,
  backordered,
  storeId,
  zipCode,
  quantity,
  maxInventoryAvail,
  shippingData,
  isCartTiles = false,
  fallbackMode = false,
  isSpecialOrder = false,
  isConfigurableBlinds = false,
  disableTimer = false
}) => {
  const [showCountdownTime, changeCountdownTimeStatus] = useState(true);
  const removeTimer = () => changeCountdownTimeStatus(false);
  const startDate = formatDate(deliveryStartDate, true);
  const inventoryQuantity = maxInventoryAvail;
  const deliveryOptionsText = getDeliveryOptionsText(shippingData);
  const { isLocal, isConsumerApp } = useContext(ExperienceContext);
  const showDetaTimer = ((!isConsumerApp && isLocal) || isMycartPage()) && !disableTimer;

  if (isConfigurableBlinds || isSpecialOrder) {
    return null;
  }

  return (
    <div className="sui-w-full">
      {!fallbackMode && showDetaTimer && (
        <div className="sui-mt-3 sui-mb-2">
          <DetaCountDownTimer
            dynamicEtaMinutes={dynamicEtaMinutes}
            dynamicEtaHours={dynamicEtaHours}
            showCountdownTime={showCountdownTime}
            inventoryQuantity={inventoryQuantity}
            removeTimer={removeTimer}
            deliveryStartDate={deliveryStartDate}
            isLongDayName={false}
            deliveryTimeline={deliveryTimeline}
            startDate={startDate}
            zipCode={zipCode}
            isCartTiles={isCartTiles}
          />
        </div>
      )}
      <DeliveryDetails
        itemId={itemId}
        storeId={storeId}
        zipCode={zipCode}
        quantity={quantity}
        shippingData={shippingData}
        isCartTiles={isCartTiles}
        fallbackMode={fallbackMode}
      />
    </div>
  );
};

DefaultShippingDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  backordered: PropTypes.bool,
  deliveryStartDate: PropTypes.string,
  deliveryTimeline: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    isAnchor: PropTypes.bool,
    inventory: PropTypes.shape({
      quantity: PropTypes.number,
      isInStock: PropTypes.bool,
      isLimitedQuantity: PropTypes.bool,
      isBackordered: PropTypes.bool
    })
  })),
  dynamicEtaMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dynamicEtaHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shippingServiceModel: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    freeShippingThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasFreeShipping: PropTypes.bool,
    dynamicETA: PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalHours: PropTypes.string,
      hasCountdownTimer: PropTypes.bool
    })
  }),
  quantity: PropTypes.number,
  zipCode: PropTypes.string,
  storeId: PropTypes.string,
  maxInventoryAvail: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  shippingData: PropTypes.objectOf(PropTypes.any),
  isCartTiles: PropTypes.bool,
  fallbackMode: PropTypes.bool,
  isSpecialOrder: PropTypes.bool,
  isConfigurableBlinds: PropTypes.bool,
  disableTimer: PropTypes.bool
};

DefaultShippingDetails.defaultProps = {
  quantity: 1,
  shippingServiceModel: null,
  backordered: false,
  deliveryStartDate: null,
  deliveryTimeline: null,
  locations: [{
    isAnchor: false,
    inventory: {
      quantity: null,
      isInStock: false,
      isLimitedQuantity: null,
      isBackordered: false
    }
  }],
  dynamicEtaMinutes: null,
  dynamicEtaHours: null,
  zipCode: null,
  storeId: null,
  maxInventoryAvail: null,
  shippingData: {},
  isCartTiles: false,
  fallbackMode: false,
  isSpecialOrder: false,
  isConfigurableBlinds: false,
  disableTimer: false
};

export { DefaultShippingDetails };
