/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { string, number, bool } from 'prop-types';
import { QueryProvider, extend } from '@thd-nucleus/data-sources';
import { Button } from '@one-thd/sui-atomic-components';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import { AddToQuoteDrawer } from './drawers/AddToQuoteDrawer';
import { useQuoteProduct } from './hooks/useQuotes';
import { dataModel } from '../dataModels/dataModel';
import { useCustomerInformation } from './hooks/useCustomerInformation';

const AddToQuote = ({
  itemId, quantity, fulfillmentLocation, fulfillmentMethod, disabled
}) => {
  const { deliveryZip: deliveryZipCode } = useContext(ExperienceContext);
  const store = useStore();
  const [drawerShowing, setDrawerShowing] = useState(false);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-quote.ready');
  }, []);

  const customerInfo = useCustomerInformation();

  const { quoteProduct } = useQuoteProduct({ itemId });
  const product = quoteProduct?.product;

  let isDisabled;
  if (disabled === null) {
    isDisabled = !podFulFillmentUtils.isATCEnabled(product) || podFulFillmentUtils.isAppliance(product);
  } else {
    isDisabled = disabled;
  }
  const fulfillmentMethodFromFulfillmentComponent = podFulFillmentUtils.getFulfillmentMethod(product);
  const fulfillmentLocationFromProductPodUtils = ProductPodUtils.getFulfillmentLocation({
    fulfillmentMethod: fulfillmentMethodFromFulfillmentComponent,
    store,
    product,
    deliveryZip: deliveryZipCode
  });

  const openDrawer = () => {
    setDrawerShowing(true);
  };

  return (
    <>
      <Button
        variant="secondary"
        fullWidth
        onClick={openDrawer}
        disabled={isDisabled}
        data-testid="add-to-quote"
        data-component="AddToQuote"
      >
        Add to Quote
      </Button>
      {drawerShowing && (
        <QueryProvider
          cacheKey="add-to-quote-drawer"
          defaultVariables={{ svocId: customerInfo?.svocId }}
        >
          <AddToQuoteDrawer
            product={product}
            item={{
              itemId,
              quantity,
              fulfillmentMethod: fulfillmentMethod || fulfillmentMethodFromFulfillmentComponent,
              fulfillmentLocation: fulfillmentLocation || fulfillmentLocationFromProductPodUtils
            }}
            location={{
              localStoreId: store?.storeId,
              deliveryZip: deliveryZipCode
            }}
            drawerShowing={drawerShowing}
            setDrawerShowing={setDrawerShowing}
          />
        </QueryProvider>
      )}
    </>
  );
};

AddToQuote.propTypes = {
  itemId: string.isRequired,
  quantity: number.isRequired,
  fulfillmentMethod: string,
  fulfillmentLocation: string,
  disabled: bool
};

AddToQuote.defaultProps = {
  fulfillmentMethod: '',
  fulfillmentLocation: '',
  disabled: null
};

AddToQuote.displayName = 'AddToQuote';

AddToQuote.dataModel = extend(dataModel);

export { AddToQuote };
