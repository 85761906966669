import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS,
  DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS, EXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS, MSB_SUBEXPERIENCE_TAGS,
  PROMOTION_TAGS
} from './constants';

const getSanitizedRewardTiers = (promotion) => {
  const tiers = promotion.reward?.tiers || [];

  return tiers
    .slice()
    // Sorts promotion tiers from smallest to largest quantity or amount needed to qualify
    .sort((
      {minThresholdVal: aVal},
      {minThresholdVal: bVal}) => {
      return (aVal < bVal) ? -1 : 1;
    })
    // Guarantees each tier has default value for each key and sets the tier index
    // this changes backend "nulls" to 0. but would we actually prefer the nulls?
    .map((tier, i) => {
      return {
        tier: i,
        minThresholdVal: tier.minThresholdVal || 0,
        thresholdType: tier.thresholdType || '',
        maxAllowedRewardAmount: tier.maxAllowedRewardAmount || 0,
        rewardVal: tier.rewardVal || 0,
        rewardType: tier.rewardType || '',
        rewardLevel: tier.rewardLevel || ''
      };
    });
};

const getMinimumPurchaseQuantityBound = (itemGroup, isBXG1Enabled) => {
  if (itemGroup === 'SRC-1') {
    return isBXG1Enabled
      ? 2.0
      : 1.0;
  }
  if (itemGroup === 'TGT-1') {
    return isBXG1Enabled
      ? 2.0
      : 1.0;
  }

  return 1.0;
};

const boundedMinimumPurchaseQuantity = (pr, bound) => {
  if (!(pr && pr.thresholdType && pr.minThresholdVal)) {
    return false;
  }
  return pr.thresholdType === 'QUANTITY'
    && pr.minThresholdVal > 0
    && pr.minThresholdVal <= bound;
};

const isBogoFree = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].rewardType && tiers[0].rewardVal)) {
    return false;
  }
  return tiers[0].rewardType === 'PERCNTOFF'
    && tiers[0].rewardVal === 100.0;
};

const isBogoPercentOff = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].rewardType && tiers[0].rewardVal)) {
    return false;
  }
  return tiers[0].rewardType === 'PERCNTOFF'
    && tiers[0].rewardVal < 100.0
    && tiers[0].rewardVal > 0;
};

const isBogoDollarOff = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].rewardType && tiers[0].rewardVal)) {
    return false;
  }
  return tiers[0].rewardType === 'AMTOFF'
    && tiers[0].rewardVal > 0;
};

const isBackwardsBogo = (skuItemGroup) => {
  return skuItemGroup === 'TGT-1';
};

const isQuantityPercentageOff = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].minThresholdVal && tiers[0].rewardVal
    && tiers[0].thresholdType && tiers[0].rewardType)) {
    return false;
  }
  return tiers[0].thresholdType === 'QUANTITY'
    && tiers[0].rewardType === 'PERCNTOFF';
};

const isQuantityAmountPerOrder = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].minThresholdVal && tiers[0].rewardVal
    && tiers[0].thresholdType && tiers[0].rewardLevel)) {
    return false;
  }
  return tiers[0].thresholdType === 'QUANTITY'
    && tiers[0].rewardLevel === 'ORDER';
};

const isQuantityAmountPerItem = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].minThresholdVal && tiers[0].rewardVal
    && tiers[0].thresholdType && tiers[0].rewardLevel)) {
    return false;
  }
  return tiers[0].thresholdType === 'QUANTITY'
    && tiers[0].rewardLevel === 'ITEM';
};

const isAmountPercentageOff = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].minThresholdVal && tiers[0].rewardVal
    && tiers[0].thresholdType && tiers[0].rewardType)) {
    return false;
  }
  return tiers[0].thresholdType === 'AMOUNT'
    && tiers[0].rewardType === 'PERCNTOFF';
};

const isAmountAmountPerOrder = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].minThresholdVal && tiers[0].rewardVal
    && tiers[0].thresholdType && tiers[0].rewardLevel)) {
    return false;
  }
  return tiers[0].thresholdType === 'AMOUNT'
    && tiers[0].rewardLevel === 'ORDER';
};

const isAmountAmountPerItem = (tiers) => {
  if (tiers?.length > 0 && !(tiers[0].minThresholdVal && tiers[0].rewardVal
    && tiers[0].thresholdType && tiers[0].rewardLevel)) {
    return false;
  }
  return tiers[0].thresholdType === 'AMOUNT'
    && tiers[0].rewardLevel === 'ITEM';
};

const isRegularBogoFree = (qualifying, reward, tiers) => {
  return boundedMinimumPurchaseQuantity(qualifying, 1.0)
    && boundedMinimumPurchaseQuantity(reward, 1.0)
    && isBogoFree(tiers);
};

const isRegularBogoPercent = (qualifying, reward, tiers) => {
  return boundedMinimumPurchaseQuantity(qualifying, 1.0)
    && boundedMinimumPurchaseQuantity(reward, 1.0)
    && isBogoPercentOff(tiers);
};

const isRegularBogoDollar = (qualifying, reward, tiers) => {
  return boundedMinimumPurchaseQuantity(qualifying, 1.0)
    && boundedMinimumPurchaseQuantity(reward, 1.0)
    && isBogoDollarOff(tiers);
};

const isBXG1Free = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(
      qualifying,
      getMinimumPurchaseQuantityBound(qualifying.itemGroup, isBXG1Enabled)
    )
    && boundedMinimumPurchaseQuantity(reward, 1.0)
    && isBogoFree(tiers);
};

const isBXG1Percent = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(
      qualifying,
      getMinimumPurchaseQuantityBound(qualifying.itemGroup, isBXG1Enabled)
    )
    && boundedMinimumPurchaseQuantity(reward, 1.0)
    && isBogoPercentOff(tiers);
};

const isBXG1Dollar = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(
      qualifying,
      getMinimumPurchaseQuantityBound(qualifying.itemGroup, isBXG1Enabled)
    )
    && boundedMinimumPurchaseQuantity(reward, 1.0)
    && isBogoDollarOff(tiers);
};

const isB1GYFree = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(qualifying, 1.0)
    && boundedMinimumPurchaseQuantity(
      reward,
      getMinimumPurchaseQuantityBound(reward.itemGroup, isBXG1Enabled)
    )
    && isBogoFree(tiers);
};

const isB1GYPercent = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(qualifying, 1.0)
    && boundedMinimumPurchaseQuantity(
      reward,
      getMinimumPurchaseQuantityBound(reward.itemGroup, isBXG1Enabled)
    )
    && isBogoPercentOff(tiers);
};

const isB1GYDollar = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(qualifying, 1.0)
    && boundedMinimumPurchaseQuantity(
      reward,
      getMinimumPurchaseQuantityBound(reward.itemGroup, isBXG1Enabled)
    )
    && isBogoDollarOff(tiers);
};

const isBXGYFree = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(
      qualifying,
      getMinimumPurchaseQuantityBound(qualifying.itemGroup, isBXG1Enabled)
    )
    && boundedMinimumPurchaseQuantity(
      reward,
      getMinimumPurchaseQuantityBound(reward.itemGroup, isBXG1Enabled)
    )
    && isBogoFree(tiers);
};

const isBXGYPercent = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(
      qualifying,
      getMinimumPurchaseQuantityBound(qualifying.itemGroup, isBXG1Enabled)
    )
    && boundedMinimumPurchaseQuantity(
      reward,
      getMinimumPurchaseQuantityBound(reward.itemGroup, isBXG1Enabled)
    )
    && isBogoPercentOff(tiers);
};

const isBXGYDollar = (qualifying, reward, isBXG1Enabled, tiers) => {
  return boundedMinimumPurchaseQuantity(
      qualifying,
      getMinimumPurchaseQuantityBound(qualifying.itemGroup, isBXG1Enabled)
    )
    && boundedMinimumPurchaseQuantity(
      reward,
      getMinimumPurchaseQuantityBound(reward.itemGroup, isBXG1Enabled)
    )
    && isBogoDollarOff(tiers);
};

const getBogoSubExperience = (tiers, qualifying, reward, skuItemGroup, isBXG1Enabled) => {
  switch (true) {
    case isRegularBogoFree(qualifying, reward, tiers): {
      return isBackwardsBogo(skuItemGroup) ? BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne
        : FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne;
    }
    case isRegularBogoPercent(qualifying, reward, tiers): {
      return isBackwardsBogo(skuItemGroup) ? BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff
        : FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff;
    }
    case isRegularBogoDollar(qualifying, reward, tiers): {
      return isBackwardsBogo(skuItemGroup) ? BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff
        : FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff;
    }
    case isBXG1Free(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne;
    }
    case isBXG1Percent(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff;
    }
    case isBXG1Dollar(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff;
    }
    case isB1GYFree(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY;
    }
    case isB1GYPercent(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff;
    }
    case isB1GYDollar(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff;
    }
    case isBXGYFree(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetY;
    }
    case isBXGYPercent(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetYPercentageOff;
    }
    case isBXGYDollar(qualifying, reward, isBXG1Enabled, tiers): {
      return FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetYDollarOff;
    }
    case isBogoFree(tiers) && tiers?.[0]?.maxAllowedRewardAmount !== null: {
      return DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne;
    }
    case isBogoPercentOff(tiers) && tiers?.[0]?.maxAllowedRewardAmount !== null: {
      return DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff;
    }
    case isBogoDollarOff(tiers): {
      return DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff;
    }
    default: {
      return null;
    }
  }
};

const getMSBSubExperience = (tiers) => {
  if (tiers != null) {
    // BuyXorMoreGetYPercent
    if (isQuantityPercentageOff(tiers)) {
      return MSB_SUBEXPERIENCE_TAGS.MSB1;
    }
    // BuyXorMoreSaveYDollar
    if (isQuantityAmountPerOrder(tiers)) {
      return MSB_SUBEXPERIENCE_TAGS.MSB2;
    }
    // BuyXorMoreSaveYDollarEach
    if (isQuantityAmountPerItem(tiers)) {
      return MSB_SUBEXPERIENCE_TAGS.MSB3;
    }
    // BuyXDollarGetYPercent
    if (isAmountPercentageOff(tiers)) {
      return MSB_SUBEXPERIENCE_TAGS.MSB4;
    }
    // BuyXDollarGetYDollar
    if (isAmountAmountPerOrder(tiers)) {
      return MSB_SUBEXPERIENCE_TAGS.MSB5;
    }
    // BuyXDollarGetYDollarEach
    if (isAmountAmountPerItem(tiers)) {
      return MSB_SUBEXPERIENCE_TAGS.MSB6;
    }
  }
  return null;
};

const getSubExperienceTag = (promotionTags, tiers, qualifying, reward, skuItemGroup, isBXG1Enabled) => {
  if (promotionTags?.length) {
    if (promotionTags.includes(EXPERIENCE_TAGS.BOGO)) {
      return getBogoSubExperience(tiers, qualifying, reward, skuItemGroup, isBXG1Enabled);
    } else if (promotionTags.includes(EXPERIENCE_TAGS.MSB)) {
      return getMSBSubExperience(tiers);
    } else if (promotionTags.includes(EXPERIENCE_TAGS.BMSM)) {
      return EXPERIENCE_TAGS.BMSM;
    }
  }
  return null;
};

export const transformResponseToContextValues = ({promotion, isBXG1Enabled}) => {
  const src1EligibilityCriteria = promotion.eligibilityCriteria?.find(
    (criterion) => criterion.itemGroup === 'SRC-1') || {};
  const tgt1EligibilityCriteria = promotion.eligibilityCriteria?.find(
    (criterion) => criterion.itemGroup === 'TGT-1') || {};
  const rewardTiers = getSanitizedRewardTiers(promotion);
  const skuItemGroup = promotion?.skuItemGroup;
  const promotionTags = promotion?.promotionTags;
  const subExperience = getSubExperienceTag(promotionTags, rewardTiers,
    src1EligibilityCriteria, tgt1EligibilityCriteria, skuItemGroup, isBXG1Enabled);

  return {
    subExperience,
    rewardTiers,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria
  };
};
