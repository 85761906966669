import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { IDS } from '../../automationTestIds';
import { track } from '../../analytics';

export const DeliveryHeader = ({
  itemId,
  zipCode,
  showCheckAvailability,
  setShowCheckAvailability,
  isCartTiles,
  lineItemId,
  fulfillmentMethod,
  applianceCountInCart,
  channel,
  isMajorAppliance,
  openApplianceCheckAvailability
}) => {
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? 'https://www.homedepot.com'
      : window.location.origin;
  }

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
    track('click', {
      origin: 'delivery-options',
      eventName: 'check fulfillment availability',
      primaryCategory: 'button submission'
    });
  };

  const cartReqParams = {
    itemId,
    lineItemId,
    zipCode,
    host,
    enableNucleusDrawer: true,
    fulfillmentMethod,
    digitalDataFlag: true
  };

  const openCheckAvailability = () => {
    if (window.location.href.includes('/mycart/home')) {
      openApplianceCheckAvailability({ lineItemId, shippingType: fulfillmentMethod, channel });
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('cart.check-availability', {
        cartReqParams
      });
    }
  };

  const availabilityStencilButton = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      classNamecomponent="span"
      onClick={handleOnClick}
      data-testid="delivery-header-zipcode-link"
    >
      { zipCode }
    </Link>
  );
  const appCheckAvailabilityButton = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      classNamecomponent="span"
      color="primary"
      onClick={openCheckAvailability}
      data-testid="delivery-header-zipcode-link"
    >
      { zipCode }
    </Link>
  );

  const applZipCode = (applianceCountInCart === 1) ? appCheckAvailabilityButton : zipCode;

  return (
    <div
      className="sui-flex sui-w-1/2 sui-pl-2 sui-mt-auto"
      data-automation-id={IDS.LABEL.deliveryToLabel}
    >
      <Typography variant="body-base" height="normal">
        {'Delivering to '}
        <span className="sui-cursor-pointer">{ isCartTiles
          && isMajorAppliance ? applZipCode : availabilityStencilButton }
        </span>
      </Typography>
    </div>
  );
};

DeliveryHeader.propTypes = {
  itemId: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired,
  isCartTiles: PropTypes.bool,
  lineItemId: PropTypes.string,
  fulfillmentMethod: PropTypes.string.isRequired,
  applianceCountInCart: PropTypes.number,
  channel: PropTypes.string.isRequired,
  isMajorAppliance: PropTypes.bool.isRequired,
  openApplianceCheckAvailability: PropTypes.func.isRequired
};

DeliveryHeader.defaultProps = {
  isCartTiles: false,
  lineItemId: '',
  applianceCountInCart: 0
};
