import React from 'react';
import PropTypes from 'prop-types';
import { DeliveryOptions } from '@thd-olt-component-react/delivery-options';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import {
  getDeliveryOptionsText,
  showTodayOrTomorrow
} from '../../../helper/utils';

const DeliveryDetails = ({
  itemId,
  storeId,
  zipCode,
  quantity,
  shippingData,
  isCartTiles = false,
  fallbackMode = false
}) => {

  const deliveryOptionsText = getDeliveryOptionsText(shippingData);

  return (
    <>
      {(!isCartTiles && !fallbackMode && zipCode && (
        <div className="sui-flex-row sui-pt-3 sui-pb-3">
          <Typography variant="body-base">
            {deliveryOptionsText}
            <DeliveryOptions
              itemId={itemId}
              storeId={storeId}
              zipCode={zipCode}
              quantity={quantity}
              useDrawer
              showTodayOrTomorrow={showTodayOrTomorrow}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                data-testid="delivery-header-zipcode-link"
              >
                Delivery Details
              </Link>

            </DeliveryOptions>
          </Typography>
        </div>
      ))}
    </>
  );
};

DeliveryDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  zipCode: PropTypes.string,
  storeId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  shippingData: PropTypes.objectOf(PropTypes.any),
  isCartTiles: PropTypes.bool,
  fallbackMode: PropTypes.bool,
};

DeliveryDetails.defaultProps = {
  quantity: 1,
  zipCode: null,
  storeId: null,
  shippingData: {},
  isCartTiles: false,
  fallbackMode: false
};

export { DeliveryDetails };