/* eslint-disable max-len */
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS, DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS,
  EXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS, MSB_SUBEXPERIENCE_TAGS
} from './constants';

export const getShortenedPromoMessage = (subExperience, rewardTiers, src1EligibilityCriteria, tgt1EligibilityCriteria) => {
  switch (subExperience) {
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne: {
    return 'free-gift';
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff: {
    return `bogo${rewardTiers[0].rewardVal}%off`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff: {
    return `bogo$${rewardTiers[0].rewardVal}off`;
  }
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne: {
    return 'revbogofree';
  }
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff: {
    return `revbogod${rewardTiers[0].rewardVal}%off`;
  }
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff: {
    return `revbogod${rewardTiers[0].rewardVal}off`;
  }
  case EXPERIENCE_TAGS.BMSM: {
    return 'bmsm';
  }
  case MSB_SUBEXPERIENCE_TAGS.MSB1: {
    return `b${rewardTiers[0].minThresholdVal}plusd${rewardTiers[0].rewardVal}off`;
  }
  case MSB_SUBEXPERIENCE_TAGS.MSB2: {
    return `b${rewardTiers[0].minThresholdVal}plusd${rewardTiers[0].rewardVal}offorder`;
  }
  case MSB_SUBEXPERIENCE_TAGS.MSB3: {
    return `b${rewardTiers[0].minThresholdVal}plusd${rewardTiers[0].rewardVal}offeach`;
  }
  case MSB_SUBEXPERIENCE_TAGS.MSB4: {
    return `sp${rewardTiers[0].minThresholdVal}g${rewardTiers[0].rewardVal}off`;
  }
  case MSB_SUBEXPERIENCE_TAGS.MSB5: {
    return `sp${rewardTiers[0].minThresholdVal}g${rewardTiers[0].rewardVal}offorder`;
  }
  case MSB_SUBEXPERIENCE_TAGS.MSB6: {
    return `sp${rewardTiers[0].minThresholdVal}g${rewardTiers[0].rewardVal}offeach`;
  }
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne: {
    return `sd${rewardTiers[0].minThresholdVal}choosefree`;
  }
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff: {
    return `b${rewardTiers[0].minThresholdVal}g${rewardTiers[0].rewardVal}pctoff`;
  }
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff: {
    return `b${rewardTiers[0].minThresholdVal}gd${rewardTiers[0].rewardVal}pctoff`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne: {
    return `b${src1EligibilityCriteria.minThresholdVal}g${tgt1EligibilityCriteria.minThresholdVal}free`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff: {
    return `b${src1EligibilityCriteria.minThresholdVal}g${tgt1EligibilityCriteria.minThresholdVal}-${rewardTiers[0].rewardVal}pctoff`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff: {
    return `b${src1EligibilityCriteria.minThresholdVal}g${tgt1EligibilityCriteria.minThresholdVal}d${rewardTiers[0].rewardVal}off`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY: {
    return `b${src1EligibilityCriteria.minThresholdVal}g${tgt1EligibilityCriteria.minThresholdVal}free`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff: {
    return `b${src1EligibilityCriteria.minThresholdVal}g${tgt1EligibilityCriteria.minThresholdVal}${rewardTiers[0].rewardVal}percentoff`;
  }
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff: {
    return `b${src1EligibilityCriteria.minThresholdVal}g${tgt1EligibilityCriteria.minThresholdVal}dollar${rewardTiers[0].rewardVal}off`;
  }
  default: {
    return null;
  }
  }
};

export const analyticsPromoClickEvent = () => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('promotion.click');
  }
};

export const triggerNewRelicEvent = (props) => {
  try {
    if (typeof window !== 'undefined' && window?.newrelic) {
      window.newrelic.addPageAction(
        props.actionName,
        {
          component: 'Promotion',
          ...props.customAttributes
        }
      );
    }
  } catch (err) {
    console.error(`NewRelic event logging failed for ${props.actionName}`, err);
  }
};

export const setProductImpressionData = (itemId, data) => {
  try {
    if (typeof window?.digitalData !== 'undefined') {
      if (!window?.digitalData?.content) {
        window.digitalData.content = {};
        window.digitalData.content.product = [];
      }
      const objIndex = window.digitalData.content.product.findIndex((obj) => obj.productInfo.sku === itemId);
      if (objIndex >= 0) {
        const haveBadge = window.digitalData.content.product[objIndex].productInfo.badge.some((badge) => badge.name === data.name);
        if (!haveBadge) {
          window.digitalData.content.product[objIndex].productInfo.badge.push(data);
        }
      }
    }
  } catch (err) {
    console.error('Error setting product impression data:', err);
  }
};
