import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { Typography } from '@one-thd/sui-atomic-components';
import { FBTContext } from '../contexts/FBTContext';
import { FBTPlaceholder } from '../FBTPlaceholder.component';
import { RecsProductPod } from './RecsProductPod';
import {
  getImageURL,
} from '../helpers/helpers';
import styles from '../thd-recs-fbt.module.scss';

const cx = classNames.bind(styles);
export const RecsFBT = () => {

  const {
    RecsFbtResponseData,
    RecsFbtResponseDataLoading,
    productResponseData,
    productResponseLoading,
    originalConfigId,
    isFulfillable,
    isDisplayableBasedOnFulfillment,
    checkedItems,
    dynamicTitle
  } = useContext(FBTContext);

  const product = productResponseData?.product;
  if (productResponseLoading || RecsFbtResponseDataLoading) {
    return (
      <FBTPlaceholder />
    );
  }
  if (!product) return null;

  const bundleProducts = RecsFbtResponseData?.products;
  if (!bundleProducts?.length) return null;
  if (!isFulfillable) return null;
  if (!isDisplayableBasedOnFulfillment) return null;
  const normalizeSkus = ({ products }) => {
    return products.map((productObj, productIndex) => {
      // primary item is a frontEnd model response
      const {
        idStartDate,
        idEndDate,
        idPromoDetails,
        category,
        description,
        isAnchor,
        product: {
          identifiers: {
            canonicalUrl: canonicalURL,
            itemId,
            brandName,
            productLabel
          },
          badges,
          pricing,
          media: {
            images
          },
          reviews
        }
      } = productObj;

      const averageRating = reviews?.ratingsReviews?.averageRating;
      const totalReviews = reviews?.ratingsReviews?.totalReviews;

      // ssr should check the first item
      const checked = typeof window === 'undefined' && productIndex === 0
        ? true
        : (checkedItems.indexOf(itemId) > -1);

      if (productIndex === 0) {
        const image = images?.find((i) => i.subType === 'PRIMARY');
        const imageURL = image ? image.url : '';
        return {
          brand: brandName,
          canonicalURL,
          badges,
          pricing,
          idStartDate,
          idEndDate,
          idPromoDetails,
          category,
          itemId,
          originalConfigId,
          productName: productLabel,
          rating: averageRating,
          reviews: totalReviews,
          imageURL,
          checked,
          product: productObj.product,
          type: 'primary'
        };
      }
      return {
        brand: brandName,
        canonicalURL,
        badges,
        pricing,
        productName: productLabel,
        rating: averageRating,
        reviews: totalReviews,
        imageURL: getImageURL(images),
        itemId,
        idPromoDetails,
        category,
        description,
        isAnchor,
        checked,
        product: productObj.product,
        type: 'product',
        strategy: productObj.strategy
      };
    });
  };
  const normalizedProducts = normalizeSkus({ products: [{ product }, ...bundleProducts] });

  return (
    <div className={cx('frequently-bought-together')} data-type="container" data-component="RecsFBT">
      <meta data-prop="name" content="pip_fbt" />
      <div className="sui-py-4">
        <Typography variant="h2">{dynamicTitle}</Typography>
      </div>
      <RecsProductPod normalizedProducts={normalizedProducts} itemId={product.itemId} />
    </div>
  );
};
