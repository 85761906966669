import { useLazyDataModel, useDataModel } from '@thd-nucleus/data-sources';
import * as types from '../shared/Constants';

const _isAdmin = (props) => props?.role === types.USER_ADMIN;

// ************************* //
/* REQUEST BUILDER SECTION */
// ************************* //
const _quoteRequest = (opts = {}) => {
  let quoteRequest = {};
  // for get all quotes
  if (opts.isGetQuote) {
    quoteRequest = {
      pageSize: opts.pageSize
    };
    if (_isAdmin(opts)) {
      // eslint-disable-next-line no-param-reassign
      opts.userId = '';
    }
    quoteRequest = {
      ...quoteRequest,
      pageNumber: 1,
      sortBy: 'createdDt',
      sortOrder: 'desc',
      quoteStatus: 'all'
    };
    // for adding or creating a quote
  } else {
    // include deliveryZip for delivery fulfillments
    let deliveryZip = null;
    if (types.DELIVERY_FULFILLMENT.includes(opts.fulfillmentMethod)) {
      deliveryZip = opts.deliveryZip;
    }
    // for lineItems
    const lineItems = [
      {
        itemId: opts.itemId,
        quantity: opts.quantity,
        fulfillmentLocation: deliveryZip || opts.fulfillmentLocation,
        fulfillmentMethod: opts.fulfillmentMethod
      }
    ];
    // for creating new quote
    if (opts.isNew) {
      quoteRequest = {
        createQuoteRequest: {
          cartName: opts.quoteName,
          deliveryZip,
          lineItems,
          localStoreId: opts.localStoreId
        }
      };
    } else {
      // for adding to existing quote
      quoteRequest = {
        addToQuoteRequest: {
          deliveryZip,
          lineItems,
          localStoreId: opts.localStoreId,
          quoteId: opts.quoteId
        }
      };
    }
  }
  return {
    svocId: opts.svocId,
    userId: opts.userId,
    ...quoteRequest
  };
};

// *********************** //
/* ACTUAL HOOKS SECTION */
// ********************** //
const useQuoteProduct = (props) => {
  const { data: quoteProduct } = useDataModel('product', {
    variables: {
      itemId: props.itemId
    },
    skip: !props.itemId
  });
  return { quoteProduct };
};

const useCreateQuote = (props) => {
  const [createQuote, quoteResponse] = useLazyDataModel('createQuote', {
    variables: _quoteRequest(props),
    ssr: false,
    refetchQueries: ['quoteList']
  });
  return [createQuote, quoteResponse];
};

const useAddToQuote = (props) => {
  const [addToQuote, quoteResponse] = useLazyDataModel('addToQuote', {
    variables: _quoteRequest(props),
    ssr: false,
    refetchQueries: ['quoteList']
  });
  return [addToQuote, quoteResponse];
};

const useGetQuoteList = (props) => {
  const {
    refetch,
    data: quoteData,
    loading: quoteLoading,
    error: quoteError
  } = useDataModel('quoteList', {
    variables: _quoteRequest(props),
    fetchPolicy: 'cache-first'
  });
  return { refetch, quoteData, quoteLoading, quoteError };
};

export { useQuoteProduct, useCreateQuote, useAddToQuote, useGetQuoteList };
