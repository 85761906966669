/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Drawer, DrawerHeader } from '@one-thd/sui-atomic-components';
import { useConfigService } from '@thd-nucleus/experience-context';
import { AddToQuoteFormDrawer } from './AddToQuoteFormDrawer';
import { CreateNewQuoteDrawer } from './CreateNewQuoteDrawer';
import { SuccessDrawer } from './SuccessDrawer';
import { ErrorDrawer } from './ErrorDrawer';
import { SignInDrawer } from './SignInDrawer';
import { DrawerSkeleton } from '../shared/DrawerSkeleton';
import { useGetQuoteList } from '../hooks/useQuotes';
import { dataModel } from '../../dataModels/dataModel';
import {
  GENERIC_ERROR,
  DEFAULT_SELECTED_QUOTE,
  QUOTE_ACTIVE_STATUS,
  QUOTE_PENDING_STATUS
} from '../shared/Constants';
import { useCustomerInformation } from '../hooks/useCustomerInformation';

const AddToQuoteDrawer = ({
  drawerShowing, item, location, product, setDrawerShowing
}) => {
  const [quotesPageSize, setQuotesPageSize] = useState(20);
  const [selectedQuote, setSelectedQuote] = useState(DEFAULT_SELECTED_QUOTE);
  const [error, setError] = useState(null);
  const [quoteName, setQuoteName] = useState('');
  const [successful, setSuccess] = useState(false); // used because of refetch causing loading
  const [quoteList, setQuoteList] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [addToQuoteFormDrawer, setAddToQuoteFormDrawer] = useState(false);
  const [createNewQuoteDrawer, setCreateNewQuoteDrawer] = useState(false);
  const [errorDrawer, setErrorDrawer] = useState(false);
  const [signInDrawer, setSignInDrawer] = useState(false);
  const [deliveryZip, setDeliveryZip] = useState(location.deliveryZip);
  const customerInfo = useCustomerInformation();

  /**
   * This component will be consumed in different places, like buybox on PIP,
   * and product pod in BuyItAgain and Lists. There are differences
   * in the `QueryProvider` configuration between these places that change
   * `fetchPolicy`, so we need to set one explicity that works for us no matter
   * where we are.
   *
   * For example, when on a PLP-like page, the `QueryProvider` will typically be
   * configured with dataSource prop equal to `searchNav`, which will override
   * the default `fetchPolicy` from `cache-first` to `cache-only`. This does
   * not work for our `getQuoteList` call below since we need to make the
   * call at least once to get data, hence the explicit fetchPolicy=cache-first
   * to check cache first, then make a network call if necessary.
   */

  const { refetch, quoteData, quoteLoading, quoteError } = useGetQuoteList({
    ...customerInfo,
    pageSize: quotesPageSize,
    isGetQuote: true
  });
  const { totalNbrOfQuotes } = quoteData?.getQuoteList || quoteData?.quoteList?.pagination || {};

  const isAccessTypeValid = (accessType) => {
    // hide if accessType on 1 or 2, show if null or not 1 or 2
    return !accessType || (accessType && accessType !== 1 && accessType !== 2);
  };

  const statusIsValid = (status) => {
    const upperCased = status.toUpperCase();
    return upperCased === QUOTE_ACTIVE_STATUS || upperCased === QUOTE_PENDING_STATUS;
  };

  const shouldShowItem = ({ status, accessType }) => {
    return statusIsValid(status) && isAccessTypeValid(accessType);
  };

  useEffect(() => {
    if (quotesPageSize > 20) {
      refetch();
    }
    const quotes = quoteData?.getQuoteList?.quotes || quoteData?.quoteList?.quotes || [];
    const filteredQuotes = quotes.filter(({ status, accessType }) => {
      return shouldShowItem({ status, accessType });
    }) || [];
    setQuoteList(filteredQuotes);
    const index = quotes.length;
    if (quotes[index - 1]?.status === 'Expired' || totalNbrOfQuotes === quoteList?.length) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  }, [quotesPageSize, quoteData]);

  useEffect(() => {
    const quotes = quoteData?.getQuoteList?.quotes || quoteData?.quoteList?.quotes || [];
    const filteredQuotes = quotes.filter(({ status, accessType }) => {
      return shouldShowItem({ status, accessType });
    }) || [];
    if (drawerShowing && !quoteList.length && filteredQuotes.length) {
      setQuoteList(filteredQuotes);
    }
    const index = quotes.length - 1;
    if (quotes[index]?.status === 'Expired' || totalNbrOfQuotes === quoteList?.length) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  }, [drawerShowing, quoteList, quoteData]);

  useEffect(() => {
    if (quoteError && /AUTH/.test(quoteError.message)) {
      setSignInDrawer(true);
    }
    if (!quoteLoading && quoteError) {
      setErrorDrawer(true);
    }
    if (drawerShowing && quoteData && !quoteLoading && !successful && !quoteError) {
      if (totalNbrOfQuotes > 0) {
        setAddToQuoteFormDrawer(true);
        window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.addToQuoteOverlay');
      } else if (totalNbrOfQuotes === 0) {
        setCreateNewQuoteDrawer(true);
        window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.createAQuoteOverlay');
      }
    }
  }, [quoteData, quoteLoading, quoteError, drawerShowing]);

  useEffect(() => {
    if (successful && location.deliveryZip !== deliveryZip) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.deliveryZipCodeOverlay');
    }
  }, [successful]);

  const getErrorMessage = () => {
    if (!error) return '';
    const { message, graphQLErrors } = error;

    // check is API error message
    if (!message || message === 'null') {
      if (graphQLErrors.length) {
        const { extensions } = graphQLErrors[0];
        const { errorData } = extensions;
        if (errorData.length) {
          const { errors } = errorData[0];
          if (errors.length) return errors[0].description;
        }
      }
    }

    // Check if generic from server without helpful info
    if (/(50|51|40|41)/.test(message)) return GENERIC_ERROR;

    // Otherwise return the message
    return error.message;
  };

  const closeDrawer = () => {
    setDrawerShowing(false);
    setAddToQuoteFormDrawer(false);
    setCreateNewQuoteDrawer(false);
    setErrorDrawer(false);
    setSuccess(false);
    setSignInDrawer(false);
    setQuoteName('');
    setSelectedQuote(DEFAULT_SELECTED_QUOTE);
  };

  return (
    <Drawer
      data-testid="add-to-quote-drawer"
      open={drawerShowing}
      onClose={closeDrawer}
      DrawerRootProps={{ 'data-component': 'AddToQuoteDrawer' }}
    >
      <DrawerHeader title="Add to Quote" onClose={closeDrawer} />
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        {quoteLoading ? (
          <DrawerSkeleton />
        ) : (
          <>
            {addToQuoteFormDrawer && (
              <AddToQuoteFormDrawer
                item={item}
                location={{ localStoreId: location.localStoreId, deliveryZip }}
                onClose={closeDrawer}
                product={product}
                quoteList={quoteList}
                quotesPageSize={quotesPageSize}
                selectedQuote={selectedQuote}
                setAddToQuoteFormDrawer={setAddToQuoteFormDrawer}
                setCreateNewQuoteDrawer={setCreateNewQuoteDrawer}
                setDeliveryZip={setDeliveryZip}
                setError={setError}
                setErrorDrawer={setErrorDrawer}
                setQuoteName={setQuoteName}
                setQuotesPageSize={setQuotesPageSize}
                setSelectedQuote={setSelectedQuote}
                setSuccess={setSuccess}
                showLoadMore={showLoadMore}
              />
            )}
            {createNewQuoteDrawer && (
              <CreateNewQuoteDrawer
                item={item}
                location={{ localStoreId: location.localStoreId, deliveryZip }}
                onClose={closeDrawer}
                product={product}
                setCreateNewQuoteDrawer={setCreateNewQuoteDrawer}
                setError={setError}
                setErrorDrawer={setErrorDrawer}
                setQuoteName={setQuoteName}
                setSuccess={setSuccess}
              />
            )}
          </>
        )}
        {successful && <SuccessDrawer quoteName={quoteName} onClose={closeDrawer} />}
        {signInDrawer && <SignInDrawer onClose={closeDrawer} />}
        {errorDrawer && <ErrorDrawer message={getErrorMessage()} onClose={closeDrawer} />}
      </div>
    </Drawer>
  );
};

AddToQuoteDrawer.propTypes = {};

AddToQuoteDrawer.displayName = 'AddToQuoteDrawer';

AddToQuoteDrawer.dataModel = dataModel;

export { AddToQuoteDrawer };
