/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { DeliveryExpress } from '@one-thd/sui-icons';
import { useConfigService } from '@thd-nucleus/experience-context';
import { DEFAULT_APPLIANCE_DELIVERY_THRESHOLD, FULFILLMENT_METHODS } from '../constants';
import { getSTHServiceLevelCount } from '../helper/utils';
import { isUnavailableOrOutOfStock, meetsApplianceDeliveryThreshold } from '../../pod/helpers/pod-fulfillment-utils';
import {
  DeliveryMessageUnderTilePlaceholder
} from './Placeholder/DeliveryMessageUnderTilePlaceholder';
import { getDeliveryMessageContent, getDeliveryMessageVars } from './deliveryMessageUnderTileHelper';

export const DeliveryMessageUnderTile = React.memo(({
  shippingData, bodfsFulfillment, currDeliveryTileFfm, isShippingLoading,
  isBuyBoxLite, isMajorAppliance, applianceDeliveryData, isCartTiles, pricing, isBODFSCartMessagingOn = false,
  enableApplianceDeliveryCharge, enableFreeDeliveryForExchange, isExchangeCustomer, bodfsCarDeliveryPriceValue,
  applianceDeliveryThreshold
}) => {
  const bodfsCarDeliveryPrice = useConfigService('bodfsCarDeliveryPrice') || bodfsCarDeliveryPriceValue;
  const isBODFSCartMessaging = useConfigService('isBODFSCartMessagingOn') || isBODFSCartMessagingOn;
  const isBodfsAvailable = !isUnavailableOrOutOfStock(bodfsFulfillment);
  const sthServiceLevelCount = getSTHServiceLevelCount(shippingData);
  const bodfsDeliveryTimeline = bodfsFulfillment?.deliveryTimeline?.toLowerCase();
  const isCartBODFSMessagingEnabled = isBODFSCartMessaging && isCartTiles;
  const isDeliverySthOnly = !isCartBODFSMessagingEnabled && !isBodfsAvailable && sthServiceLevelCount > 0;
  const priceForFreeShip = meetsApplianceDeliveryThreshold(pricing, applianceDeliveryThreshold);
  const isMajorApplianceDeliveryType = currDeliveryTileFfm === FULFILLMENT_METHODS.APPLIANCE;

  const {
    applianceFlatChargeApplies,
    forceFreeShippingForExchange,
    isApplianceDataLoading,
    isMinimumFreeShipPrice,
    noDeliveryAvailable
  } = getDeliveryMessageVars({
    applianceDeliveryData,
    enableApplianceDeliveryCharge,
    enableFreeDeliveryForExchange,
    shippingData,
    isBodfsAvailable,
    isExchangeCustomer,
    isMajorAppliance,
    priceForFreeShip
  });

  if (!shippingData && !isBuyBoxLite && (isShippingLoading || isApplianceDataLoading)) {
    return <DeliveryMessageUnderTilePlaceholder />;
  }

  if (noDeliveryAvailable || isDeliverySthOnly || isBuyBoxLite || (isCartTiles && !isBODFSCartMessaging)) {
    return null;
  }

  const isSameBODFSDeliveryPriceFS = !!(bodfsFulfillment?.deliveryCharge?.toString()
  === bodfsCarDeliveryPrice?.toString());

  const {
    bodyText,
    bodyTextAdditional = '',
    freeText,
    isTextWrapping = false,
    titleText
  } = getDeliveryMessageContent({
    applianceFlatChargeApplies,
    bodfsDeliveryTimeline,
    bodfsFulfillment,
    forceFreeShippingForExchange,
    isBodfsAvailable,
    isCartTiles,
    isMajorApplianceDeliveryType,
    isMinimumFreeShipPrice,
    isSameBODFSDeliveryPriceFS
  });

  if (!titleText || !bodyText) { return null; }

  return (
    <div className="sui-pt-2">
      <span className="sui-flex">
        <span className="sui-w-8" data-testid="delivery-express-truck-icon">
          <DeliveryExpress size="large" />
        </span>
        <Typography variant="body-base">
          <div className="sui-pl-2">
            {freeText && <span className="sui-font-bold sui-text-success">{freeText} </span>}
            <span className="sui-font-bold">{titleText}</span>{' '}
            {isTextWrapping ? <span>{bodyText}</span> : <div>{bodyText}</div>}
            {bodyTextAdditional && <div className="sui-font-bold">{bodyTextAdditional}</div>}
          </div>
        </Typography>
      </span>
    </div>
  );
}, (prevProps, nextProps) => {
  // This "propsAreEqual" method is used to determine if we re-render the DeliveryMessageUnderTile component,
  // or just pull from React.memo() memory cache.
  const rerenderCondition = [
    prevProps?.currDeliveryTileFfm !== nextProps?.currDeliveryTileFfm,
    JSON.stringify(prevProps?.bodfsFulfillment) !== JSON.stringify(nextProps?.bodfsFulfillment),
    JSON.stringify(prevProps?.shippingData) !== JSON.stringify(nextProps?.shippingData),
    prevProps?.isShippingLoading !== nextProps?.isShippingLoading
  ];

  // Check if any condition is true, indicating a prop change
  const shouldRerender = rerenderCondition.some((condition) => condition);

  // Return true to prevent re-render if props have not changed
  return !shouldRerender;
});

DeliveryMessageUnderTile.propTypes = {
  /*  shippingData is the GraphQL data from shipping query, If STH is not available, this prop be null */
  shippingData: PropTypes.objectOf(PropTypes.any),
  /*  If BODFS ffm exists for product, bodfsFulfillment will be populated  */
  bodfsFulfillment: PropTypes.objectOf(PropTypes.any),
  /*  The current Fulfillment shown on Delivery Tile  */
  currDeliveryTileFfm: PropTypes.oneOf([
    FULFILLMENT_METHODS.STH, FULFILLMENT_METHODS.BODFS, FULFILLMENT_METHODS.APPLIANCE
  ]).isRequired,
  /* is the shipping graphQL data loading */
  isShippingLoading: PropTypes.bool.isRequired,
  isBuyBoxLite: PropTypes.bool,
  isMajorAppliance: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  applianceDeliveryData: PropTypes.object,
  isCartTiles: PropTypes.bool,
  pricing: PropTypes.object,
  isBODFSCartMessagingOn: PropTypes.bool,
  enableApplianceDeliveryCharge: PropTypes.bool,
  enableFreeDeliveryForExchange: PropTypes.bool,
  applianceDeliveryThreshold: PropTypes.number,
  isExchangeCustomer: PropTypes.bool,
  bodfsCarDeliveryPriceValue: PropTypes.string
};

DeliveryMessageUnderTile.defaultProps = {
  shippingData: null,
  bodfsFulfillment: null,
  isBuyBoxLite: false,
  isMajorAppliance: false,
  applianceDeliveryData: null,
  isCartTiles: false,
  pricing: { value: 0 },
  isBODFSCartMessagingOn: false,
  enableApplianceDeliveryCharge: false,
  enableFreeDeliveryForExchange: false,
  applianceDeliveryThreshold: DEFAULT_APPLIANCE_DELIVERY_THRESHOLD,
  isExchangeCustomer: false,
  bodfsCarDeliveryPriceValue: ''
};
