export const MESSAGE_KEY = Object.freeze({
  plpMessage: 'plpMessage'
});

export const BACKWARDS_BOGO_SUBEXPERIENCE_TAGS = Object.freeze({
  buyOneGetOne: 'buyOneGetOneBackwards',
  buyOneGetPercentageOff: 'buyOneGetPercentageOffBackwards',
  buyOneGetDollarOff: 'buyOneGetDollarOffBackwards',
  buyOneGetY: 'buyOneGetYBackwards',
  buyOneGetYPercentageOff: 'buyOneGetYPercentageOffBackwards',
  buyOneGetYDollarOff: 'buyOneGetYDollarOffBackwards',
  buyXGetOne: 'buyXGetOneBackwards',
  buyXGetOnePercentageOff: 'buyXGetOnePercentageOffBackwards',
  buyXGetOneDollarOff: 'buyXGetOneDollarOffBackwards',
  buyXGetY: 'buyXGetYBackwards',
  buyXGetYPercentageOff: 'buyXGetYPercentageOffBackwards',
  buyXGetYDollarOff: 'buyXGetYDollarOffBackwards',
});

export const DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS = Object.freeze({
  buyMinAmountGetOne: 'buyMinAmountGetOne',
  buyMinAmountGetDollarOff: 'buyMinAmountGetDollarOff',
  buyMinAmountGetPercentageOff: 'buyMinAmountGetPercentageOff'
});

export const EXPERIENCE_TAGS = Object.freeze({
  BOGO: 'BOGO',
  BMSM: 'BMSM',
  MSB: 'MSB'
});

export const PROMOTION_TAGS = Object.freeze({
  BULK: 'BULK'
});

export const FORWARDS_BOGO_SUBEXPERIENCE_TAGS = Object.freeze({
  buyOneGetOne: 'buyOneGetOne',
  buyOneGetPercentageOff: 'buyOneGetPercentageOff',
  buyOneGetDollarOff: 'buyOneGetDollarOff',
  buyOneGetY: 'buyOneGetY',
  buyOneGetYPercentageOff: 'buyOneGetYPercentageOff',
  buyOneGetYDollarOff: 'buyOneGetYDollarOff',
  buyXGetOne: 'buyXGetOne',
  buyXGetOnePercentageOff: 'buyXGetOnePercentageOff',
  buyXGetOneDollarOff: 'buyXGetOneDollarOff',
  buyXGetY: 'buyXGetY',
  buyXGetYPercentageOff: 'buyXGetYPercentageOff',
  buyXGetYDollarOff: 'buyXGetYDollarOff',
});

export const MSB_SUBEXPERIENCE_TAGS = Object.freeze({
  MSB1: 'MSB1',
  MSB2: 'MSB2',
  MSB3: 'MSB3',
  MSB4: 'MSB4',
  MSB5: 'MSB5',
  MSB6: 'MSB6',
});

export const MESSAGE_NUMBER_WORD_MAP = Object.freeze({
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
});

export const MESSAGE_PLURAL = Object.freeze({
  none: 'none', // exactly 0
  one: 'one', // exactly 1
  two: 'two', // exactly 2
  many: 'many', // more than 2
  other: 'other' // default if none others are true / defined
});
