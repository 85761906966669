import { FULFILLMENT_METHODS } from '../constants';
import {
  getExcludedStateName,
  isLocationInExcludedStates,
  isOutOfStockOnline,
  isStoreOnlyOOS
} from '../helper/utils';
import { getTemplate } from './templateHelper';

export const handleFallbackMode = ({
  configuratorHideQuantity,
  disableTimer,
  expressFulfillment,
  fallbackModeEnabled,
  hideShippingThreshold,
  isBodfsGrillsAssemblyEligible,
  isCartTiles,
  isConfigurableBlinds,
  isSpecialOrder,
  itemId,
  quantity,
  shippingData,
  shippingFulfillment,
  storeId,
  zipCode
}) => {
  const selectedFulfillment = shippingFulfillment || expressFulfillment;
  if (!fallbackModeEnabled || !selectedFulfillment) {
    return null;
  }
  let subTemplateFields = {
    fallbackMode: true,
    isCartTiles,
    isConfigurableBlinds,
    isSpecialOrder,
    itemId,
    locations: selectedFulfillment?.locations,
    quantity,
    shippingData,
    storeId,
    zipCode
  };

  let templateFields = {
    configuratorHideQuantity,
    hideShippingThreshold,
    isBodfsGrillsAssemblyEligible,
    isSpecialOrder,
    type: selectedFulfillment?.type,
    isBlindsItem: selectedFulfillment?.isBlindsItem
  };

  let method; let
    subTemplateName;
  if (shippingFulfillment) {
    method = FULFILLMENT_METHODS.STH;
    subTemplateName = 'DefaultShippingDetails';
    subTemplateFields = {
      ...subTemplateFields,
      disableTimer
    };
    templateFields = {
      ...templateFields,
      disableTimer,
      hasFreeShipping: true,
      testID: 'sth-instock'
    };
  } else {
    method = FULFILLMENT_METHODS.BODFS;
    subTemplateName = 'ExpressDeliveryDetails';
    templateFields = {
      ...templateFields,
      totalCharge: selectedFulfillment.totalCharge,
      testID: 'bodfs-instock'
    };
  }
  const templateName = 'DefaultShipping';
  return {
    ...getTemplate({
      ffm: 'DELIVERY',
      subTemplateFields,
      subTemplateName,
      templateFields,
      templateName
    }),
    enabled: true,
    method,
    quantityLimitExceed: false
  };
};

export const handleFulfillable = ({
  bodfsLocation,
  configuratorHideQuantity,
  disableSTH = false,
  disableTimer,
  expressFulfillment,
  fulfillable,
  fulfillment,
  hideShippingThreshold,
  isBodfsGrillsAssemblyEligible,
  isCartTiles,
  isConfigurableBlinds,
  isShippingFulfillment,
  isSpecialOrder,
  itemId,
  maxInventoryAvail,
  quantity,
  shippingData,
  shippingFulfillment,
  shippingLocation,
  storeId,
  zipCode
}) => {
  const selectedFulfillment = isShippingFulfillment ? shippingFulfillment : expressFulfillment;
  if (!fulfillable || !selectedFulfillment) {
    return null;
  }
  let subTemplateFields = {
    backordered: fulfillment?.backordered,
    isCartTiles,
    isConfigurableBlinds,
    isSpecialOrder,
    itemId,
    locations: selectedFulfillment?.locations,
    maxInventoryAvail,
    quantity,
    shippingData,
    storeId,
    zipCode
  };
  let templateFields = {
    backordered: fulfillment?.backordered,
    backorderedShipDate: fulfillment?.backorderedShipDate,
    configuratorHideQuantity,
    consolidatedDeliveryCharge: selectedFulfillment?.consolidatedDeliveryCharge,
    deliveryEndDate: selectedFulfillment?.deliveryDates?.endDate,
    deliveryStartDate: selectedFulfillment?.deliveryDates?.startDate,
    deliveryTimeline: selectedFulfillment?.deliveryTimeline,
    hasFreeShipping: selectedFulfillment?.hasFreeShipping,
    hideShippingThreshold,
    isBodfsGrillsAssemblyEligible,
    isCartTiles,
    isSpecialOrder,
    lineItemId: fulfillment?.lineItemId,
    maxCartPriceContributor: selectedFulfillment?.maxCartPriceContributor,
    maxInventoryAvail,
    selected: selectedFulfillment?.selected,
    totalCharge: selectedFulfillment?.totalCharge,
    type: selectedFulfillment?.type,
    isBlindsItem: selectedFulfillment?.isBlindsItem
  };
  let method; let subTemplateName; let
    quantityLimitExceed;
  if (isShippingFulfillment) {
    method = FULFILLMENT_METHODS.STH;
    subTemplateName = 'DefaultShippingDetails';
    subTemplateFields = {
      ...subTemplateFields,
      deliveryStartDate: selectedFulfillment?.deliveryDates?.startDate,
      dynamicEtaHours: selectedFulfillment?.dynamicEta?.hours,
      dynamicEtaMinutes: selectedFulfillment?.dynamicEta?.minutes,
      disableTimer
    };
    templateFields = {
      ...templateFields,
      disableTimer,
      testID: 'sth-instock'
    };
    quantityLimitExceed = shippingLocation?.inventory?.quantity > 0
      ? shippingLocation?.inventory?.quantity < quantity
      : false;
  } else {
    method = FULFILLMENT_METHODS.BODFS;
    subTemplateName = 'ExpressDeliveryDetails';
    subTemplateFields = {
      ...subTemplateFields,
      backordered: fulfillment?.backordered,
      disableSTH
    };
    templateFields = {
      ...templateFields,
      testID: 'bodfs-instock'
    };
    quantityLimitExceed = bodfsLocation?.inventory?.quantity > 0
      ? bodfsLocation?.inventory?.quantity < quantity
      : false;
  }
  const templateName = 'DefaultShipping';
  return {
    ...getTemplate({
      ffm: 'DELIVERY',
      subTemplateFields,
      subTemplateName,
      templateFields,
      templateName
    }),
    enabled: true,
    method
  };
};

export const handleNoFfmOptions = ({
  fulfillment,
  fulfillmentOptions,
  shippingState,
  setShowCheckAvailability,
  type
}) => {
  if (fulfillmentOptions || !['Online', 'Shared'].includes(type)) {
    return null;
  }
  const enabled = false;
  const method = FULFILLMENT_METHODS.STH;

  if (fulfillment?.sthExcludedShipState) {
    return {
      ...getTemplate({
        ffm: 'DELIVERY',
        templateFields: {
          stateName: getExcludedStateName(shippingState || fulfillment?.sthExcludedShipState),
          setShowCheckAvailability
        },
        templateName: 'OffshoreSthUnavailable'
      }),
      method,
      enabled,
      excluded: true
    };
  }

  return {
    ...getTemplate({
      ffm: 'SHARED',
      templateFields: {
        type: FULFILLMENT_METHODS.STH
      },
      templateName: 'NotAvailable'
    }),
    method,
    enabled,
    outOfStock: true,
    showNotify: true
  };
};

export const handleUnavailableFfms = ({
  fulfillment,
  isExcludedState,
  isMockOrExpress,
  isShippingFulfillment,
  itemId,
  quantity,
  setShowCheckAvailability,
  shippingFulfillment,
  shippingState,
  storeId,
  zipCode
}) => {
  if ((!isShippingFulfillment && !isExcludedState) || isMockOrExpress) {
    return null;
  }

  const method = FULFILLMENT_METHODS.STH;

  if (fulfillment?.sthExcludedShipState) {
    return {
      ...getTemplate({
        ffm: 'DELIVERY',
        templateFields: {
          itemId,
          storeId,
          zipCode,
          quantity,
          stateName: getExcludedStateName(fulfillment?.sthExcludedShipState),
          setShowCheckAvailability
        },
        templateName: 'OffshoreSthUnavailable'
      }),
      enabled: false,
      excluded: true,
      method
    };
  }

  if (isOutOfStockOnline(shippingFulfillment)) {
    return {
      ...getTemplate({
        ffm: 'SHARED',
        templateFields: {
          type: method
        },
        templateName: 'NotAvailable'
      }),
      method,
      outOfStock: true
    };
  }

  if (isLocationInExcludedStates(shippingState, fulfillment?.excludedShipStates)) {
    return {
      ...getTemplate({
        ffm: 'DELIVERY',
        templateFields: {
          stateName: getExcludedStateName(shippingState),
          type: method
        },
        templateName: 'OffshoreSthUnavailable'
      }),
      excluded: true,
      method,
      outOfStock: true
    };
  }

  return null;
};

export const handleExpressUnavailable = (expressFulfillment) => {
  if (!expressFulfillment) {
    return null;
  }
  const method = FULFILLMENT_METHODS.BODFS;
  return {
    ...getTemplate({
      ffm: 'SHARED',
      templateFields: { type: method },
      templateName: 'NotAvailable'
    }),
    enabled: false,
    method,
    outOfStock: true
  };
};

export const handleDefaultUnavailable = ({ fulfillment, type }) => {
  const method = FULFILLMENT_METHODS.STH;
  return {
    ...getTemplate({
      ffm: 'SHARED',
      templateFields: { type: method },
      templateName: 'NotAvailable'
    }),
    enabled: false,
    method,
    outOfStock: true,
    unavailable: fulfillment?.fulfillmentOptions ? isStoreOnlyOOS(fulfillment, type) : true
  };
};
