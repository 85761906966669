import {
  client,
  params,
  arrayOf,
  bool,
  shape,
  string,
  number,
  customType
} from '@thd-nucleus/data-sources';

const NumberType = number({ int: true });

const LineItem = shape({
  itemId: string(),
  quantity: string(),
  fulfillmentLocation: string(),
  fulfillmentMethod: string()
});

const ErrorProperties = shape({
  errorProperty: shape({
    name: string(),
    value: string()
  })
});

const Error = shape({
  id: string(),
  description: string(),
  submessage: string(),
  errorCode: string(),
  errorProperties: ErrorProperties
});

const ErrorData = shape({
  errors: arrayOf(Error),
  httpStatus: string(),
  userId: string(),
  customerAccountId: string(),
  correlationId: string(),
  correlationType: string()
});

const QuoteInfo = shape({
  cartId: string(),
  errorData: arrayOf(ErrorData)
});

const Quote = shape({
  name: string(),
  status: string(),
  cartId: string(),
  createdUserId: string(),
  accessType: number()
});

const GetQuote = shape({
  customer: shape({
    customerId: string(),
    userId: string()
  }),
  pagination: shape({
    totalNbrOfQuotes: NumberType,
    totalNbrOfPages: NumberType
  }),
  quotes: arrayOf(Quote)
});

const Location = shape({
  curbsidePickupFlag: bool(),
  isBuyInStoreCheckNearBy: bool(),
  distance: number({ float: true }),
  inventory: shape({
    isOutOfStock: bool(),
    isInStock: bool(),
    isLimitedQuantity: bool(),
    isUnavailable: bool(),
    quantity: number(),
    maxAllowedBopisQty: number(),
    minAllowedBopisQty: number()
  }),
  isAnchor: bool(),
  locationId: string(),
  state: string(),
  storeName: string(),
  storePhone: string(),
  type: string()
});

const Service = shape({
  deliveryTimeline: string(),
  deliveryDates: shape({
    startDate: string(),
    endDate: string()
  }),
  deliveryCharge: string(),
  dynamicEta: shape({
    hours: string(),
    minutes: string()
  }),
  hasFreeShipping: bool(),
  freeDeliveryThreshold: number({ float: true }),
  locations: arrayOf(Location),
  type: string(),
  totalCharge: number({ float: true })
});

const Image = shape({
  url: string(),
  type: string(),
  subType: string()
});

export const dataModel = {
  product: params({
    itemId: string().isRequired(),
    dataSource: string()
  }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      brandName: string(),
      productLabel: string(),
      productType: string()
    }),
    media: shape({ images: arrayOf(Image) }),
    pricing: params({
      storeId: string()
    }).shape({
      value: number({ float: true })
    }),
    fulfillment: client(
      params({
        storeId: string(),
        zipCode: string()
      }).shape({
        anchorStoreStatus: bool(),
        anchorStoreStatusType: string(),
        backordered: bool(),
        backorderedShipDate: string(),
        bossExcludedShipStates: string(),
        excludedShipStates: string(),
        seasonStatusEligible: bool(),
        fulfillmentOptions: arrayOf(
          shape({
            type: string(),
            fulfillable: bool(),
            services: arrayOf(Service)
          })
        ),
        onlineStoreStatus: bool(),
        onlineStoreStatusType: string()
      })
    )
  }),

  quoteList: params({
    svocId: string().isRequired(),
    userId: string(),
    pageNumber: string(),
    pageSize: string(),
    quoteStatus: string(),
    sortBy: string(),
    sortOrder: string()
  }).shape(GetQuote),

  createQuote: params({
    svocId: string().isRequired(),
    userId: string().isRequired(),
    createQuoteRequest: customType('QuoteRequest')
      .shape({
        localStoreId: string().isRequired(),
        cartName: string().isRequired(),
        deliveryZip: string(),
        lineItems: arrayOf(LineItem)
      })
      .isRequired()
  })
    .mutation()
    .shape(QuoteInfo),

  addToQuote: params({
    svocId: string().isRequired(),
    userId: string().isRequired(),
    addToQuoteRequest: customType('QuoteRequest')
      .shape({
        localStoreId: string().isRequired(),
        quoteId: string().isRequired(),
        deliveryZip: string(),
        lineItems: arrayOf(LineItem)
      })
      .isRequired()
  })
    .mutation()
    .shape(QuoteInfo)
};
